/* Styling search edit metadata */
.metadata-edit-container{
    width: 500px;
    height: 95vh;
    overflow-y: scroll;
    text-align: center;
}

/* .metadata-edit-container:hover{
    overflow-y: auto;
} */

.metadata-edit-container div{
    width: 275px
}
.metadata-dropdowns {
    text-align: left;
}
.metadata-edit-container p{
    font-weight: bold;
    font-size: 15px;
    margin-top:20px
}

.metadata-edit-submit{
    margin-left: 105px;
    margin-bottom: 20px;
}

.edit-button{
    padding-left:20px
}

.metadata-edit-submit button{
    color: white;
    background-color: brown;
}

.metadata-edit-submit button:hover{
    color: darkgrey;
    background-color: darkred;
}

/* Styling Metadata form  */
.metadata-edit-container div label{
    color: black;
    margin-left: -4px;
    font-size: 18px;
    font-weight: bold;
    font-style: oblique;
 }

  