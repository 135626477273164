.upload-files-display-container{
    height: auto;
}

.upload-button-container{
    margin-top: 20px;
    text-align: center;
}

.filepond--item>.filepond--file-wrapper, .filepond--item>.filepond--panel {
    background-color: white;
}

div.filepond--file{
    color: black;
    padding: .4625em;
    border: 2px solid darkred;
}

.upload-button-container span.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary{
    background-color: darkred;
    color:white
}

.upload-button-container span.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary:hover{
    background-color: #263238;
    color:white
}

.upload-button-container span span svg{
    margin-left: 10px;
}

.progress-bar.progress-bar-animated.progress-bar-striped{
    background-color: darkred;
}


/* Progress Bar Styling */
.progress-bar-container{
    position: relative;
    margin-bottom: 20px;
    width: 98%;
    padding-left: 2%;
}

div.progress{
    height: 1.5rem;
}

div.progress .progress-bar.progress-bar-animated.progress-bar-striped{
    font-size: 16px;
}

.progress-bar-text{
    text-align: center;
}