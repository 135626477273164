/* Real estate styling */
.realestate-container{
    margin-top: 10px;
}
.realestate-container div div select {
    height: 25px;
}

.MuiOutlinedInput-multiline {
    padding: 0px !important;
}

p.MuiFormHelperText-contained{
    margin-top: 0px;
}