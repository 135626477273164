.search-refresh-logo svg{ 
    height:48px;
    width: 48px;
}

.search-refresh-logo svg:hover{
    cursor: pointer;
    color: black;
} 

.search-refresh-logo{
    padding-left:95.5%;
    margin-top: 7px;
    margin-left: -5%;
}

.outer-searchbar-div{
    margin-top: -120px;
    margin-left:  70px;
    margin-right: 70px;
}

.MuiInputBase-input .MuiInput-input {
    color: #AC0000;
    background-color: #AC0000;
}

.MuiNativeSelect-select:focus{
    background-color:#AC0000 ;
    color:black
}

 /* .MuiInputBase-root .MuiNativeSelect-icon {
 color: white;
}

#demo-customized-select-native1{
    background-color:#AC0000 ;
}

#demo-customized-select-native1 .MuiNativeSelect-icon{
    color:white;
} */

