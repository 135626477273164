/* Index PDF container format */
.main-index-container{
    width: 100%;
    padding: 2px;
    border: 0.5px solid #ced4da;
    height: 100vh;
}
.main-index-container div.pdf {
    width: 70%;
    text-align: center;
    height: 95vh;
    float: left;
}

#formcontrol-batchid div{
    width: 300px;
}

.main-index-container div.doc {
    width: 70%;
    text-align: center;
    height: 95vh;
    overflow-y: hidden;
    float: left;
}

.main-index-container div.doc:hover {
    overflow-y: auto;
}

.main-index-container div.pdf div canvas{
    border: 4px solid black;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
}
/* .main-index-container div.doc div div div div{
    border: 4px solid black;
} */

.outer-index-container{
    /* background-color: #e6e6e6; */
    background-color: white;
    /* padding: 56px 4px 0px 4px; */
    /* padding-top: 56px; */
    border: 2px solid lightgray;
}

/* Metadata container format */
.main-index-container div.metadata-outer-container{
   width: 30%;
   display: inline-block;
   background-color: #e6e6e6;
   height: 95vh;
   overflow-y: auto;
}

/* Dropdown with usernames formatter */
.username-dropdown-container{
    margin-bottom: 10px;
    margin-left: 10px;
}

.username-dropdown-container div div div{
    width: 200px;
}

.username-dropdown div{
    margin-right: 20px;
}

.username-dropdown-container div div select{
    font-weight: bold;
    font-size: 15px;
    color: black;
}

.workflow-dropdown-container div div div{
    width: auto;
}

.refresh-logo{
    float: right;
}

/* Refresh Logo Alignment */
.refresh-logo svg{
    height:45px;
    width:45px;
}

.refresh-logo svg:hover{
    cursor: pointer;
    color:black
}

/* Horizontal scrolling for pending document tab */
.MuiTabs-scroller.MuiTabs-fixed{
    overflow: auto !important;
}

/* Footer buttons styling */
.footer-button{
    width: 100%;
    display: flex;
}

.footer-button-submit{
    padding-left: 73.5%;
}

.pg-viewer-wrapper .document-container {
    width: 800px;
}

.pg-viewer-wrapper {
    overflow-y:scroll;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;  
}

.pg-viewer-wrapper .pg-viewer {
    height: 100%;
    position: relative;
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
}

/* Doc viewer style */
#docx > div div{
    page-break-after: always;
}

.document-container {
    padding: 30px;
    width: 700px;
    background: grey;
    margin: auto;
  }
  #docx {
    width:50%;
    height: 100%;
  }