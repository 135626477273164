.MuiTableRow-head .MuiTableCell-root {
  border-bottom: none;
  padding: 8px 0px 0px 8px;
  font-size: 18px;
  font-weight: 400;
  color: #060606;
  /* text-align: center; */
}

.MuiTableBody-root .MuiTableCell-root {
  padding: 0px 0px 8px 8px;
  font-size: 16px;
}
.MuiList-root .MuiMenu-list .MuiList-padding {
  font-size: 12px;
}
.MuiListItemText-root .MuiTypography-body1 {
  font-size: 18px;
}
/* .MuiFormControl-root .MuiSelect-selectMenu {
  font-size: 12px;
} */

.MuiTableRow-head .MuiTableCell-root {
  font-size: 15px;
  font-weight: 600;
}
.MuiTab-wrapper {
  font-weight: 600;
}
/* .MuiFormControl-root .MuiSelect-selectMenu {
  font-size: 14px;
  font-family: 'calibri';
} */

.MuiFormLabel-root {
  font-size: 16px;
}

.file-container{
  width: 100%;
  padding: 2px;
  border: 0.5px solid #ced4da;
  height: 100vh;
}

.file-container div.doc {
  width: 100%;
  text-align: center;
  height: 95vh;
  overflow-y: hidden;
  float: left;
}

/* changing color of Link */

a{
  color:rgba(0, 0, 0, 0.54);
  text-decoration: none;
  background-color: transparent;
}

.react-date-picker__calendar {
z-index: 10;
}

.makeStyles-appBar-2{
  z-index: 1500;
}

/* .css-21xts8-ToastContainer {
  z-index: 2000;
  top:50%;
}

.css-1frfeuj-ToastContainer{
  z-index: 2000;
  top:50%;
} */

div.react-toast-notifications__container{
top:10%
}

.MuiFormHelperText-root.Mui-error {
  color: #f44336;
  background-color: #e6e6e6;
}

p.MuiFormHelperText-contained {
  margin-left: 0px;
  margin-right: 0px;
}

a:hover {
  color: #AC0000;
  text-decoration: none;
}
a {
  color:  #AC0000;
}

.fixed_table > div div div div div{
  height: 100%;
  /* overflow: auto; */
  max-height: 57vh;
  /* overflow-x: auto; */
}

.fixed_dialog_table > div div div div div{
  height: 100%;
  /* overflow: auto; */
  max-height: 45vh;
  /* overflow-x: auto; */
}

.progress-bar {
  background-color: #AC0000
  }

  /* for WF count */
  .MuiBottomNavigationAction-label.MuiBottomNavigationAction-iconOnly{
    opacity:1 !important;
  }

  .MuiBottomNavigationAction-root.MuiBottomNavigationAction-iconOnly{
    padding-top: 0px !important;
  }
  
  .MuiTabs-scroller.MuiTabs-fixed {
    overflow: hidden !important
  }

 .MuiSnackbarContent-action{
  display: flex;
  /* align-items: center; */
  margin-left: 0px !important;
  margin-right: -8px;
  padding-left: 4px !important;
  font-weight: 900;
  /* text-decoration: underline; */
 }

 .MuiSnackbarContent-root{
   background-color: dimgrey;
 }

 .react-datepicker-popper {
  z-index: 9999 !important;
}

.start-date {
  text-align: center;
  font-weight: bold;
  
}
/* ::-webkit-scrollbar {
  -webkit-appearance: none;
} */

::-webkit-scrollbar:horizontal {
  height: 15px !important;
  /* width:20px !important; */
};
/* ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  border: '2px solid white';
  background-color: 'rgba(0, 0, 0, .3)';
}; */

.MuiTypography-caption{
  font-weight: bolder;
}

.MuiTypography-root .MuiTypography-caption {
 color: rgba(0, 0, 0, 0.99)
}

.MuiIconButton-root.Mui-disabled  {
  color: rgba(0, 0, 0, 0.7) !important;
}

