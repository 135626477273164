/* Applying formatting on metadata elements */
.metadata-cont {
    justify-content: center;
    display: flex;
}
.metadata-container{
    
    text-align: center;
    justify-content: center;
}


.metadata-p{
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

.metadata-dropdowns{
    text-align: center;
}

.metadata-dropdowns div{
    width: 500px;
}

.metadata-textfield{
    text-align: center;
}

.metadata-textfield div{
    width: 500px;
}

.metadata-datefield{
    text-align: center;
}

.metadata-datefield div{
    width: 500px;
    text-align: left
}

.metadata-datefield-labels{
    text-align: left;
    padding-left: 1.2%
}

.metadata-submit{
    padding-top: 30px;
    text-align: center;
}

.metadata-submit button{
    color: white;
    background-color: brown;
}

.metadata-submit button:hover{
    color: darkgrey;
    background-color: darkred;
}

/* Styling the errors */
.metadata-dropdowns .required-field-error{
    color: red;
    font-weight: bold;
    margin-bottom: -25px;
    text-align: right;
    margin-right: 40px;
}

.metadata-textfield .required-field-error{
    color: red;
    font-weight: bold;
    margin-bottom: -10px;
    text-align: right;
    margin-right: 40px;
}

.metadata-datefield .required-field-error{
    color: red;
    font-weight: bold;
    margin-bottom: -30px;
    text-align: right;
    margin-right: 40px;
}

/* Styling labelss and boxes */
.metadata-dropdowns div label{
    color: black;
    font-size: 18px;
    font-weight: bold;
}

.metadata-textfield div label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.Mui-focused.Mui-focused.Mui-required.Mui-required{
    font-weight: bold;
    font-size: 17px;
    margin-left: -3px;
    color: black;
    font-style: normal;
}

.metadata-textfield div label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.Mui-required.Mui-required{
    font-style: italic;
    font-weight: 545;
    color: black
}

.metadata-textfield div label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined{
    font-style: italic;
    font-weight: 545;
    color: black
}

.metadata-textfield div label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.Mui-focused.Mui-focused{
    font-weight: bold;
    font-size: 17px;
    margin-left: -3px;
    color: black;
    font-style: normal;
}

.metadata-textfield div label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled.Mui-required.Mui-required{
    font-weight: bold;
    font-size: 18px;
    margin-left: -3px;
    color: black;
    font-style: normal;
}

.metadata-textfield div label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled{
    font-weight: bold;
    font-size: 18px;
    margin-left: -3px;
    color: black;
    font-style: normal;
}

.metadata-datefield .metadata-datefield-labels{
    font-weight: bold;
}