.metadata-container{
    text-align: center;
}
/* styling Datepicker div across all content types */
.common-datepicker{
    margin-top: 15px;
}
.common-datepicker div{
    width: 300px;
    background-color: white;
    text-align: left;
    /* if you try to set the height of the datepicker it will alter the styling of the calendar that comes on */
}
.common-datepicker p{
    margin-right:195px;
    font-size: 15.5px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: bold;
    margin-bottom: 7px;
}
/* Save and Go Back Button */
.save-goback-button-container button{
    margin-left: 15px;
}
/* Styling 4 components */
.contenttype-container div label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
    font-size: 20px;
    font-weight: bold;
}

.metadata-contentype-form div label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink{
    font-size: 20px;
    font-weight: bold;
}

.locked-container button.MuiButton-contained.Mui-disabled{
    color:darkred
}


/* Disabled color style for location related fields */
#realestate-locationname, #realestate-state, #realestate-city, #realestate-region,
#palease-locationname, #palease-state, #palease-city, #palease-region,
#pacamrebillunexpected-locationname, #pacamrebillunexpected-state, #pacamrebillunexpected-city, #pacamrebillunexpected-region,
#pacamrebillrecon-locationname, #pacamrebillrecon-state, #pacamrebillrecon-city, #pacamrebillrecon-region,
#pacamrebillbudget-locationname, #pacamrebillbudget-state, #pacamrebillbudget-city, #pacamrebillbudget-region,
#pacamunexpected-locationname, #pacamunexpected-state, #pacamunexpected-city, #pacamunexpected-region,
#pacamrecon-locationname, #pacamrecon-state, #pacamrecon-city, #pacamrecon-region,
#pacampayablebudget-locationname, #pacampayablebudget-state, #pacampayablebudget-city, #pacampayablebudget-region,
#paCAMPayableBudgetObj-expiryyear,
#paCAMPayableReconciliationObj-expiryyear, #paCAMPayableUnexpectedObj-expiryyear, #paCAMPayableRebillObj-expiryyear,
#paCAMRebillReconciliationObj-expiryyear, #paCAMRebillUnexpectedObj-expiryyear
{
    color: black;
}

.MuiAutocomplete-root.autocomplete div{
    height: 55px;
}

.MuiAutocomplete-root.autocomplete div div{
    padding: 0;
    padding-right: 0;
}

.MuiAutocomplete-root.autocomplete{ margin-bottom: 32px;}