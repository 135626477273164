#formcontrol-bulk-select{
    min-width: 400px;
    text-align: initial;
    font-weight: 200;
}

.shiftMargin{
    margin: 8px 8px 0px 8px!important;
}

#key-selection-column
{
    background-color: red;
}

.MuiTypography-caption {
    color: rgba(0, 0, 0, 0.99)
   }